<template>
  <div class="nodeList">
    <Nav></Nav>
    <div class="mynodeCon">
      <div class="con0" @click="JumpNode">
        <div></div>
        <div>{{lang('fanhui')}}</div>
      </div>
      <div class="con1">
        <div class="con1L">
          <p class="reactivePc"><b>{{myInfoObj.name}}</b></p>
          <p class="reactiveMob"><b>{{myInfoObj.name}}</b></p>
          <p class="reactivePc">{{myInfoObj.slogan}}</p>
          <p class="reactiveMob">{{myInfoObj.slogan}}</p>
        </div>
        <div class="con1R" @click="showVote(queryName,query)"  v-if="myInfoObj.canDeposit==true">
          <div></div>
          <div>{{lang('toupiao')}}</div>
        </div>
        <div class="con1T  cursor-pointer" v-if="myInfoObj.canDeposit==false" style="color: red">
              {{lang('pr')}}
        </div>
      </div>
      <div class="con2">
        <div class="conNum">
          <div class="a">
            <p><b>{{myInfoObj.rate}}%</b></p>
            <p class="n">{{lang('shouyifenpeibili')}}</p>
          </div>
          <div class="a">
            <p><b>{{BigNumberStr(myInfoObj.totalAmount,18,2)}}</b></p>
            <p class="n">{{lang('nodetotalpl')}}</p>
          </div>
          <div class="a">
            <p><b>{{BigNumberStr(myInfoObj.totalReward,18,3)}}</b></p>
            <p class="n">{{lang('leijifafangshouyi')}}</p>
          </div>
          <div class="a">
            <p v-if="myInfoObj.canDeposit==true"><b>{{BigNumberStr(myInfoObj.dailyReward, 18,3)}}</b></p>
            <p v-if="myInfoObj.canDeposit==false"><b>0</b></p>
            <p class="n">{{lang('estimated')}}</p>
          </div>
        </div>
      </div>
      <div class="con3">
        <div class="top">
          <div class="topL">{{lang('jiediancanxuanzhiyashu')}}</div>
          <div class="topR">
           <span v-if="myInfoObj.totalAmount==0">{{ BigNumberStr(myInfoObj.depositAmount, 18) }}&nbsp; &nbsp;丨0%</span>
           <span  v-if="myInfoObj.totalAmount!=0">{{ BigNumberStr(myInfoObj.depositAmount, 18) }}&nbsp; &nbsp;丨&nbsp;&nbsp;{{BigNumberStr((myInfoObj.depositAmount/myInfoObj.totalAmount)* 100,0,3)}}%</span>
          </div>
        </div>
        <div class="bot">
          <div class="botL">{{lang('jiedianzhuanshulianjie')}}</div>
          <div class="botR">
            <span class="reactiveMob">{{cutNameLink(dcescription+$store.state.accounts[0])}}</span>
            <span class="reactivePc">{{dcescription+$store.state.accounts[0]}}</span>
            <van-button
            type="primary"
            class="btn3"
            v-clipboard:copy="dcescription+query"
            v-clipboard:success="onCopy"
            v-clipboard:error="onError"
            ></van-button>
          </div>
        </div>
      </div>
      <div class="con5">
        <div class="title">
          <div class="titleL">
            <span>{{lang('toupiaoyonghuliebiao')}}</span>
          </div>
        </div>
      </div>
      <div class="con4">
        <div class="conte" v-for="(item, index) in getMyList" :key="index">
          <div class="contL">
            <span v-if="index >= 3">{{index+1}}</span>
            <span v-if="index < 3"></span>
            <span>{{ cutAccount(item.addr) }}</span>
          </div>
          <div class="contR">
            <span>{{ BigNumberStr(item.amount, 18) }} TFI</span>
            <span> | </span>
             <span>{{BigNumberStr((item.amount / myInfoObj.totalAmount) * 100,0,3)}}%</span>
          </div>
        </div>
      </div>
    </div>
     <div class="Author">
    <van-dialog v-model="authorShow" show-cancel-button :showConfirmButton="false"
                :showCancelButton="false" title="">
      <div class="wrap">
        <div class="sec1">
          <!-- v-if="status==false" -->
          <div class="d1" >
          </div>
          <!-- <div class="d3" v-if="status==true">
          </div> -->
          <!-- <div class="d2">{{lang('querenzhong')}}</div> -->
          <div class="d2" >{{lang('guangbojiaoyizhong')}}</div>
          <img @click="hide" class="img1" src="../../assets/img/btn_close2x.png"/>
          <div class="d8">
            <button  class="active"  @click="hide">{{lang('a_haode')}}</button>
            <button  class="active"  @click="hide">{{lang('a_chakanjiaoyi')}}</button>
          </div>
        </div>
      </div>
    </van-dialog>
  </div>
  </div>
</template>
<script>
import Nav from "../../components/Nav/Index";
import { lang , Toast } from "../../tools";
import { cutAccount,cutNameH ,cutNameLink} from "../../tools/index";
import * as metaMaskTools from "../../tools/chain";
import { mixin1, mixin2, mixin3 } from "../../tools/mixins";
import { busEvent } from '../../components/busEvent'
export default {
  name: "nodeList",
  components: { Nav },
  mixins: [mixin1, mixin2, mixin3],
  mounted() {
  },
  created(){
    let this_ = this
    busEvent.$on('getBus',function(val){
      this_.getNodeMyInfo();
      this_.getMyNodeList();
    })
  },
  computed: {
    account() {
      return this.$store.state.accounts[0];
    },
    query() {
      return  this.$route.query.projectId;
    },
    queryName() {
      return  this.$route.query.projectIdNmae;
    },
    selectName() {
      return this.$store.state.selectName;
    }
    // queryselectAddress() {
    //   return this.query
    // }
  },
  data() {
    return {
      myInfoObj:{
        rate:0,
        totalAmount:0,
        totalReward:0,
        dailyReward:0,
        depositAmount:0,
        depositReward:0,
        name:'',
        slogan:'',
      },
      getMyList: [],
      noAddr: "",
      authorShow:false, 
      address:'',
      serNum:0,
      queryselectAddress:'',
      dcescription: 'http://3.0.61.146:7000' + '/#/nodelist?projectId=',
    };
  },
  methods: {
    initPage() {
      this.getNodeMyInfo();
      this.getMyNodeList();
    },
    initData() {
    },
    cutNameLink(nameL){
        return cutNameLink(nameL);
    },
    cutNameH(name){
        return cutNameH(name);
    },
    onCopy() {
      Toast(this.lang('a_fuzhichneggon'));
    },
    onError() {
      Toast(this.lang('fuzhishibai'));
    },
    hide() {
      this.authorShow=false
    },
    async getNodeMyInfo() {
      var res = await metaMaskTools.getNodeMyInfo(this.query);
      this.myInfoObj = res;
    },
    async getMyNodeList() {
      var res = await metaMaskTools.getMyNodeList(this.query);
      this.getMyList = res;
    },
    cutAccount(account) {
      return cutAccount(account);
    },
    showVote(name,address) {
      this.$store.commit("setState", {
        voteShow: true,
        selectName:name,
        selectItem:address
      });
    },
    JumpNode() {
      this.$router.push("/node");
    },
    onRefresh(accounts) {
        this.initPage();
    },
    lang(key) {
      return lang(key);
    },
  },
};
</script>

<style scoped lang="less">
@keyframes animal {
  0%{
    transform: rotate(0deg);
    -ms-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
  }
  100%{
    transform: rotate(-360deg);
    -ms-transform: rotate(-360deg);
    -webkit-transform: rotate(-360deg);
  }
}
@media (min-width: 769px) {.mynodeCon {
  box-sizing: border-box;
  width: 100%;
  padding: 110px 40px 0 240px;
  .con0 {
     z-index: 40;
    position: fixed;
    width: 80px;
    height: 28px;
    line-height: 24px;
    border-radius: 14px;
    border: 1px solid #fff;
    background-color: #152e55;
    color: #fff;
    padding: 0 10px;
    text-align: center;
    div:nth-of-type(1) {
      width: 13.5px;
      height: 13.5px;
      background: url("../../assets/img/fenhui.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    div:nth-of-type(2) {
      display: inline-block;
      font-size: 12px;
      vertical-align: middle;
    }
  }
  .con1 {
     z-index: 40;
    position: fixed;
    display: flex;
    margin-top: 60px;
    color: #fff;
    p:nth-of-type(2) {
      font-size: 12px;
      margin-top: 8px;
    }
    .con1R {
      position: fixed;
      text-align: center;
      right: 40px;
      width: 80px;
      height: 28px;
      line-height: 24px;
      border-radius: 14px;
      border: 1px solid #fff;
      background-color: #152e55;
      color: #fff;
      padding: 0 10px;
      div:nth-of-type(1) {
        width: 13.5px;
        height: 13.5px;
        background: url("../../assets/img/fenhui.png") no-repeat;
        background-size: 100% 100%;
        color: #fff;
        display: inline-block;
        vertical-align: middle;
        margin-right: 10px;
      }
      div:nth-of-type(2) {
        display: inline-block;
        font-size: 12px;
        vertical-align: middle;
      }
    }
    .con1T {
      position: fixed;
      right: 40px;
    }
  }
  .con2 {
    margin-top: 140px;
    width: 100%;

    p {
      font-size: 15px;
      color: #fff;
    }
    .conNum {
      border-radius: 10px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      background: linear-gradient(#0077c5, #0045bb);
      margin-top: 20px;
      .a {
        text-align: center;
        width: 33.3%;
        border-right: 1px solid #ccc;
        .n {
          margin-top: 8px;
        }
      }
      .a:nth-of-type(4) {
        border-right: 0;
      }
    }
  }
  .con3 {
    width: 100%;
    margin-top: 25px;
    height: 103px;
    background-color: rgba(#0f111f, 0.4);
    border-radius: 10px;
    font-size: 12px;
    color: #fff;
    padding: 0 15px 0 9.5px;
    .top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 51.5px;
      line-height: 51.5px;
      border-bottom: 1px solid #ccc;
      .topR {
        span:nth-of-type(2) {
          display: inline-block;
          width: 65px;
          height: 22.5px;
          line-height: 22.5px;
          border: 1px solid #0077c5;
          text-align: center;
          margin-left: 20px;
          color: #0077c5;
          border-radius: 10px;
        }
      }
    }
    .bot {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 51.5px;
      line-height: 51.5px;
      .botR {
        display: flex;
      align-items: center;
        .btn3 {
          padding: 0;
          border: 0;
          display: inline-block;
          width: 15px;
          height: 15px;
          background: url("../../assets/img/fz.png") no-repeat;
          background-size: 100% 100%;
          margin-left: 20px;
        }
      }
    }
  }
  .con5 {
    margin-top: 25px;
    color: #fff;
    font-size: 12px;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .titleR {
        span {
          display: inline-block;
          margin-left: 24px;
        }
        span:nth-of-type(6) {
          width: 30px;
          height: 15px;
          background: url("../../assets/img/light.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
          margin-left: 10px;
        }
        span:nth-of-type(7) {
          width: 30px;
          height: 15px;
          background: url("../../assets/img/an.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
  }
  .con4 {
    margin-top: 20px;
    width: 100%;
    font-size: 10px;
    border-radius: 10px;
    .conte {
      width: 100%;
      padding: 0 15px 0 9.5px;
      background-color: rgba(#0f111f, 0.4);
      display: flex;
      justify-content: space-between;
      height: 51.5px;
      line-height: 51.5px;
      color: #fff;
      border-bottom: 1px solid rgba(#ccc, 0.4);
    }
    .conte:last-of-type {
      border-bottom: 0;
    }
    .contL {
      span:nth-of-type(1) {
        display: inline-block;
        width: 15px;
        height: 14.5px;
        margin-left: 4px;
      }
      span:nth-of-type(2) {
        display: inline-block;
        margin-left: 12px;
        vertical-align: middle;
      }
    }
    .contR {
      span {
        display: inline-block;
        margin-left: 20px;
      }
    }
    .conte:nth-of-type(1) .contL span:nth-of-type(1) {
      display: inline-block;
      width: 15px;
      height: 14.5px;
      background: url("../../assets/img/one.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 0 -3px 0;
    }
    .conte:nth-of-type(2) .contL span:nth-of-type(1) {
      display: inline-block;
      width: 15px;
      height: 14.5px;
      background: url("../../assets/img/two.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 0 -3px 0;
    }
    .conte:nth-of-type(3) .contL span:nth-of-type(1) {
      display: inline-block;
      width: 15px;
      height: 14.5px;
      background: url("../../assets/img/three.png") no-repeat;
      background-size: 100% 100%;
      margin: 0 0 -3px 0;
    }
    p {
      line-height: 20px;
    }
  }
}}


@media (max-width: 768px) {
  .Vote {
      .van-dialog {
        width: 300px;
      }

      .wrap {
        > .sec1 {
          padding: 0 20px 15px;

          .img1 {
            right: 21px;
            top: 16px;
          }

          .d1 {
            padding: 0 0 0 20px;
            height: 45px;
            line-height: 45px;
            font-size: 13px;
            margin-bottom: 10px;
          }
          .d6,.d7,.d8,.d9,.d10{
            font-size: 10px;
          }
          .d8{
            button{
              height: 40px;
              line-height: 40px;
              font-size: 13px;
            }
          }
        }
      }
    }
    .mynodeCon {
  box-sizing: border-box;
  width: 100%;
  padding: 0 10px 0 ;
  .con0 {
    text-align: center;  
    margin-top: 20px;
    width: 80px;
    height: 28px;
    line-height: 25px;
    border-radius: 14px;
    border: 1px solid #fff;
    background-color: #152e55;
    color: #fff;
    padding: 0 10px;
    div:nth-of-type(1) {
      width: 12px;
      height: 12px;
      background: url("../../assets/img/fenhui.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    div:nth-of-type(2) {
      display: inline-block;
      font-size: 12px;
      vertical-align: middle;
    }
  }
  .con1 {
    display: flex;
    justify-content: space-between;
    margin-top: 15px;
    color: #fff;
    .con1L {
      word-wrap:break-word;
      word-break:normal; 
      word-break:break-all;  
      font-size: 12px;  
    }
    .con1R {
       text-align: center;
       white-space:nowrap;
       right: 15px;
        width: 0.8rem;
        height: 28px;
        line-height: 25px;
        border-radius: 14px;
        border: 1px solid #fff;
        background-color: #152e55;
        color: #fff;
        padding: 0 10px;
        margin-left: 10px;
    div:nth-of-type(1) {
      width: 12px;
      height: 12px;
      background: url("../../assets/img/fenhui.png") no-repeat;
      background-size: 100% 100%;
      color: #fff;
      display: inline-block;
      vertical-align: middle;
      margin-right: 10px;
    }
    div:nth-of-type(2) {
      display: inline-block;
      font-size: 12px;
      vertical-align: middle;
    }
    }
  }
  .zanwu {
    color: rgba(#fff,.3);
    font-size: 14px;
    text-align: center;
    margin-top: 20px;
  }
  .con2 {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    p {
      font-size: 12px;
      color: #fff;
    }
    .conNum {
      border-radius: 10px;
      display: flex;
      align-items: center;
      width: 100%;
      height: 80px;
      background: linear-gradient(#0077c5, #0045bb);
      margin-top: 20px;
      .a {
        text-align: center;
        width: 33.3%;
        border-right: 1px solid #ccc;
        .n {
          margin-top: 8px;
        }
      }
      .a:nth-of-type(4) {
        border-right: 0;
      }
    }
  }
  .con3 {
    margin-top: 20px;
    width: 100%;
    background-color: rgba(#0f111f, 0.4);
    border-radius: 10px;
    font-size: 12px;
    color: #fff;
    padding: 0 15px 0 9.5px;
    .top {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 51.5px;
      line-height: 51.5px;
      border-bottom: 1px solid #ccc;
      .topR {
        span:nth-of-type(2) {
          display: inline-block;
          padding: 0 5px;
          height: 22.5px;
          line-height: 22.5px;
          border: 1px solid #0077c5;
          text-align: center;
          margin-left: 20px;
          color: #0077c5;
          border-radius: 10px;
        }
      }
    }
    .bot {
      display: flex;
      justify-content: space-between;
      width: 100%;
      height: 51.5px;
      line-height: 51.5px;    
      .botR {
        display: flex;
        align-items: center;
        justify-content: center;
        .btn3 {
          padding: 0;
          border: 0;
          display: inline-block;
          width: 0.13rem;
          height: 0.13rem;
          background: url("../../assets/img/fz.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
          margin: -3px 0 0 5px;
        }
      }
    }
  }
  .con5 {
    margin-top: 25px;
    color: #fff;
    font-size: 12px;
    .title {
      display: flex;
      justify-content: space-between;
      margin-bottom: 20px;
      .titleR {
        span {
          display: inline-block;
          margin-left: 24px;
        }
        span:nth-of-type(6) {
          width: 30px;
          height: 15px;
          background: url("../../assets/img/light.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
          margin-left: 10px;
        }
        span:nth-of-type(7) {
          width: 30px;
          height: 15px;
          background: url("../../assets/img/an.png") no-repeat;
          background-size: 100% 100%;
          vertical-align: middle;
          margin-left: 10px;
        }
      }
    }
  }
  .con4 {
    margin-top: 20px;
    width: 100%;
    font-size: 10px;
    border-radius: 10px;
    .conte {
      width: 100%;
      padding: 0 15px 0 9.5px;
      background-color: rgba(#0f111f, 0.4);
      display: flex;
      justify-content: space-between;
      height: 51.5px;
      line-height: 51.5px;
      color: #fff;
      border-bottom: 1px solid rgba(#ccc, 0.4);
    }
    .conte:last-of-type {
      border-bottom:0
    }
    .contL {
      span:nth-of-type(1) {
        display: inline-block;
        width: 15px;
        height: 14.5px;
        margin-left: 4px;
      }
      span:nth-of-type(2) {
        display: inline-block;
        margin-left: 12px;
        vertical-align:middle;
      }
    }
    .contR {
      span {
        display: inline-block;
        margin-left: 20px;
      }
    }
    .conte:nth-of-type(1) .contL span:nth-of-type(1) {
      display: inline-block;
        width: 15px;
        height: 14.5px;
        background: url("../../assets/img/one.png") no-repeat;
        background-size: 100% 100%;
        margin:0 0 -3px 0;
    }
    .conte:nth-of-type(2) .contL span:nth-of-type(1) {
      display: inline-block;
        width: 15px;
        height: 14.5px;
        background: url("../../assets/img/two.png") no-repeat;
        background-size: 100% 100%;
        margin:0 0 -3px 0;
    }
    .conte:nth-of-type(3) .contL span:nth-of-type(1) {
      display: inline-block;
        width: 15px;
        height: 14.5px;
        background: url("../../assets/img/three.png") no-repeat;
        background-size: 100% 100%;
        margin:0 0 -3px 0;
    }
    p {
      line-height: 20px;
    }
  }
}
}
</style>
